<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/machinery"
            ><a>Kartoteki</a></router-link
          >
          <router-link tag="li" to="/records/machinery"
            ><a>Maszyny</a></router-link
          >
          <router-link
            tag="li"
            :to="{
              name: 'MachineryDetails',
              params: { id: this.$route.params.id },
            }"
            class="is-active"
            ><a>{{ name }}</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/records/machinery/"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <h2 class="title level-left">{{ name }}</h2>
          </div>
          <div class="buttons level-right">
            <button @click="machineryRemove" class="button is-medium is-light">
              <icon name="trash"></icon><span>Usuń</span>
            </button>
            <router-link
              tag="a"
              :to="{
                name: 'MachineryEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-light"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
            <a
              v-if="selectADevice"
              @click="selectDevice"
              class="button is-medium is-dark"
              ><icon name="check"></icon><span>Wybierz</span></a
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="ym-whitebg pa40" v-if="machinery">
      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="machinery.name">
          <p class="label-small">Nazwa</p>
          <p class="label is-uppercase">{{ machinery.name }}</p>
        </div>
        <div class="column is-6" v-if="machinery.type">
          <p class="label-small">Typ</p>
          <p class="label is-uppercase">{{ machinery.type }}</p>
        </div>
      </div>

      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="machinery.serial">
          <p class="label-small">Numer seryjny</p>
          <p class="label is-uppercase">{{ machinery.serial }}</p>
        </div>
        <div class="column is-6">
          <p class="label-small">Dzierżawa</p>
          <p
            class="label is-uppercase"
            :style="machinery.is_rental ? 'color: red' : ''"
          >
            {{ machinery.is_rental ? "TAK" : "NIE" }}
          </p>
        </div>
      </div>
    </div>
    <div
      style="margin-top: 24px"
      v-if="
        machinery && machinery.order && Object.keys(machinery.order).length > 0
      "
    >
      <div id="toptitle" class="level ym-toptitle">
        <h2 class="title level-left">Ostatnie zlecenie</h2>
      </div>
      <div class="ym-whitebg pa40">
        <div class="columns is-variable is-3">
          <div class="column is-2" v-if="machinery.order.number">
            <p class="label-small">Numer zlecenia</p>
            <p class="label is-uppercase">{{ machinery.order.number }}</p>
          </div>
          <div class="column is-2" v-if="machinery.order.created_at">
            <p class="label-small">Data przyjęcia</p>
            <p class="label is-uppercase">{{ machinery.order.created_at }}</p>
          </div>
          <div class="column is-2" v-if="machinery.order.repared_at">
            <p class="label-small">Data naprawy</p>
            <p class="label is-uppercase">{{ machinery.order.repared_at }}</p>
          </div>
          <div class="column is-2" v-if="machinery.order.status_id">
            <p class="label-small">Status zlecenia</p>
            <p class="label is-uppercase" :style="statusColor()">
              {{ statusName().toUpperCase() }}
            </p>
          </div>
          <div class="column is-2">
            <router-link
              style="display: inline-flex;padding: 0 1rem;"
              tag="a"
              class="button is-small40 is-dark"
              :to="{
                name: 'ServiceDetails',
                params: { id: machinery.order.id },
              }"
              >więcej</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "MachineryDetails",
  components: {
    FixedHeader,
  },
  data: function() {
    return {
      machinery: {},
    };
  },
  mounted() {
    this.getMachinery();
  },
  computed: {
    ...mapGetters({
      selectADevice: "orders/selectADevice",
      order: "orders/order",
    }),
    name: function() {
      return this.machinery.name;
    },
  },
  methods: {
    ...mapActions({
      getMachineryCall: "records/getMachinery",
      removeMachineryCall: "records/removeMachinery",
    }),
    getMachinery() {
      this.getMachineryCall({ id: this.$route.params.id })
        .then((resp) => (this.machinery = resp))
        .catch((error) => {
          this.machinery = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    machineryRemove() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć maszyne?",
          content: `<span class='is-danger'>Usunięcia maszyny nie można cofnąć.</span> Maszyna pozostanie w utworzonych do tej pory zleceniach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeMachineryCall({ id: this.$route.params.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "Machinery" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    selectDevice() {
      this.$store.dispatch("orders/setDevice", this.machinery).then(() => {
        if (this.order && this.order.id) {
          this.$router.push({
            name: "ServiceEdit",
            params: { id: parseInt(this.order.id) },
          });
        } else {
          this.$router.push({ name: "ServiceAdd" });
        }
      });
    },
    statusName: function() {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.machinery.order.status_id
      );
      if (status) {
        return status[0].name;
      }
    },
    statusColor: function() {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.machinery.order.status_id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
