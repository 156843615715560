var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('portal-target',{attrs:{"name":"BreadCrumbs"}},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('router-link',{attrs:{"tag":"li","to":"/records/machinery"}},[_c('a',[_vm._v("Kartoteki")])]),_c('router-link',{attrs:{"tag":"li","to":"/records/machinery"}},[_c('a',[_vm._v("Maszyny")])]),_c('router-link',{staticClass:"is-active",attrs:{"tag":"li","to":{
            name: 'MachineryDetails',
            params: { id: this.$route.params.id },
          }}},[_c('a',[_vm._v(_vm._s(_vm.name))])])],1)])]),_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":119,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('router-link',{staticClass:"button xbtn is-medium is-transparent",attrs:{"tag":"a","to":"/records/machinery/"}},[_c('icon',{attrs:{"name":"x"}})],1),_c('h2',{staticClass:"title level-left"},[_vm._v(_vm._s(_vm.name))])],1),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-light",on:{"click":_vm.machineryRemove}},[_c('icon',{attrs:{"name":"trash"}}),_c('span',[_vm._v("Usuń")])],1),_c('router-link',{staticClass:"button is-medium is-light",attrs:{"tag":"a","to":{
              name: 'MachineryEdit',
              params: { id: parseInt(this.$route.params.id) },
            }}},[_c('icon',{attrs:{"name":"edit"}}),_c('span',[_vm._v("Edytuj")])],1),(_vm.selectADevice)?_c('a',{staticClass:"button is-medium is-dark",on:{"click":_vm.selectDevice}},[_c('icon',{attrs:{"name":"check"}}),_c('span',[_vm._v("Wybierz")])],1):_vm._e()],1)])])],1),(_vm.machinery)?_c('div',{staticClass:"ym-whitebg pa40"},[_c('div',{staticClass:"columns is-variable is-6"},[(_vm.machinery.name)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Nazwa")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.name))])]):_vm._e(),(_vm.machinery.type)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Typ")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.type))])]):_vm._e()]),_c('div',{staticClass:"columns is-variable is-6"},[(_vm.machinery.serial)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Numer seryjny")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.serial))])]):_vm._e(),_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Dzierżawa")]),_c('p',{staticClass:"label is-uppercase",style:(_vm.machinery.is_rental ? 'color: red' : '')},[_vm._v(" "+_vm._s(_vm.machinery.is_rental ? "TAK" : "NIE")+" ")])])])]):_vm._e(),(
      _vm.machinery && _vm.machinery.order && Object.keys(_vm.machinery.order).length > 0
    )?_c('div',{staticStyle:{"margin-top":"24px"}},[_vm._m(0),_c('div',{staticClass:"ym-whitebg pa40"},[_c('div',{staticClass:"columns is-variable is-3"},[(_vm.machinery.order.number)?_c('div',{staticClass:"column is-2"},[_c('p',{staticClass:"label-small"},[_vm._v("Numer zlecenia")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.order.number))])]):_vm._e(),(_vm.machinery.order.created_at)?_c('div',{staticClass:"column is-2"},[_c('p',{staticClass:"label-small"},[_vm._v("Data przyjęcia")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.order.created_at))])]):_vm._e(),(_vm.machinery.order.repared_at)?_c('div',{staticClass:"column is-2"},[_c('p',{staticClass:"label-small"},[_vm._v("Data naprawy")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.order.repared_at))])]):_vm._e(),(_vm.machinery.order.status_id)?_c('div',{staticClass:"column is-2"},[_c('p',{staticClass:"label-small"},[_vm._v("Status zlecenia")]),_c('p',{staticClass:"label is-uppercase",style:(_vm.statusColor())},[_vm._v(" "+_vm._s(_vm.statusName().toUpperCase())+" ")])]):_vm._e(),_c('div',{staticClass:"column is-2"},[_c('router-link',{staticClass:"button is-small40 is-dark",staticStyle:{"display":"inline-flex","padding":"0 1rem"},attrs:{"tag":"a","to":{
              name: 'ServiceDetails',
              params: { id: _vm.machinery.order.id },
            }}},[_vm._v("więcej")])],1)])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('h2',{staticClass:"title level-left"},[_vm._v("Ostatnie zlecenie")])])}]

export { render, staticRenderFns }